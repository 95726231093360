import Error from "@@/common/Error.vue";
import { Image as VanImage } from "vant";
import domain from "@/service/domain";
import { shopQualification } from "@api/index";
import { mapState } from "vuex";
import { env } from "md-base-tools/env";
export default {
  components: {
    VanImage,
    Error
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      data: null,
      loading: true,
      is_next: true,
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      pall: false,
      showError: false,
      authInfoType: false,
      orderListShow: false,
      message: "",
      op_type: 0,
      nft_num: 0,
      stock_hash: "",
      receive_code: "",
      status: "",
      status_name: "",
      user_info: {},
      orderData: {},
      productList: [],
      shop_id: "",
      page: 1
    };
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.titleText("经营资质");
    this.shop_id = this.$route.query.shop_id;
    await this.companyAuthorization();
  },
  methods: {
    clickpall() {
      this.pall = !this.pall;
    },
    toOrder(type) {
      location.href = `${domain.wap}/order/order_list?nav=${type}`;
    },
    toEvaluate() {
      location.href = `${domain.wap}/product/evaluate/list`;
    },
    async companyAuthorization() {
      const {
        data
      } = await shopQualification({
        // user_id: this.toUserid || this.userId,
        user_id: this.userId,
        shop_id: this.shop_id || 1
      });
      this.data = data;
    }
  }
};